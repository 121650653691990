'use client';
import {UseMutationResult} from '@tanstack/react-query';
import {FC, createContext, useContext, useEffect, useMemo} from 'react';
import {CEVENT_USER, DLVUser} from '@/constants';
import {ACCESS_TOKEN_COOKIE} from '@/constants/cookies';
import {useHandleSetCookies} from '@/hooks/useHandleSetCookies';
import {useRouter} from '@/hooks/useRouter';
import useUserData from '@/hooks/useUserData';
import {IRepCompleteSignup, useRepCompleteSignup} from '@/mutations';
import {
  ICheckMagicLinkToLogin,
  useCheckMagicLinkToLogin,
} from '@/mutations/useCheckMagicLinkToLogin';
import {ILogin, LoginDto, useLogin} from '@/mutations/useLogin';
import {IResetPassword, useResetPassword} from '@/mutations/useResetPassword';
import {ISignup, useSignup} from '@/mutations/useSignup';
import {APIError} from '@/services/request';
import {setVariable, customEvent} from '@/utils/gtm';

export interface UserProfile {
  email?: string | null;
  email_verified?: boolean | null;
  name?: string | null;
  nickname?: string | null;
  picture?: string | null;
  sub?: string | null;
  updated_at?: string | null;
}

interface IUser {
  user?: UserProfile;
  error?: APIError;
  isLoading: boolean;
  isSuccess: boolean;
  login: (_e: ILogin) => Promise<any>;
  checkMagicLinkToLogin: (_e: ICheckMagicLinkToLogin) => Promise<any>;
  resetPass: (_e: IResetPassword) => Promise<LoginDto>;
  signup: (_e: ISignup) => Promise<any>;
  repCompleteSignupMutation?: UseMutationResult<
    LoginDto,
    unknown,
    IRepCompleteSignup,
    unknown
  >;
}

const defaultState: IUser = {
  user: {},
  error: undefined,
  isLoading: false,
  isSuccess: false,
  login: () => Promise.resolve(),
  checkMagicLinkToLogin: () => Promise.resolve(),
  resetPass: async () => ({}) as LoginDto,
  signup: () => Promise.resolve(),
  repCompleteSignupMutation: undefined,
};

export const AuthContext = createContext<IUser>(defaultState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC = (props) => {
  const router = useRouter();
  const {setUserData} = useUserData();
  const {handleSetCookie} = useHandleSetCookies();

  const onLoginSuccess = (data: DLVUser) => {
    customEvent<DLVUser>(CEVENT_USER, data);
    const {user} = data;
    setVariable('user', {
      email: user.email,
      npi: user?.npi,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  };

  const loginMutation = useLogin(onLoginSuccess);
  const checkMagicLinkToLoginMutation =
    useCheckMagicLinkToLogin(onLoginSuccess);
  const signupMutation = useSignup();
  const resetPasswordMutation = useResetPassword();
  const repCompleteSignupMutation = useRepCompleteSignup();

  const accessToken =
    loginMutation.data?.accessToken ??
    checkMagicLinkToLoginMutation.data?.accessToken ??
    signupMutation.data?.accessToken ??
    resetPasswordMutation.data?.accessToken ??
    repCompleteSignupMutation.data?.accessToken;

  // update user information after successfully login/signup/resetPass
  useEffect(() => {
    if (accessToken) {
      const {firstName, lastName, redirectTo, picture} = (loginMutation.data ||
        checkMagicLinkToLoginMutation.data ||
        signupMutation.data ||
        resetPasswordMutation.data ||
        repCompleteSignupMutation.data) as LoginDto;

      if (router && redirectTo) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('redirectTo');
        searchParams.set('redirect', redirectTo);
        router.push(`${router.pathname}?${searchParams.toString()}`);
      }

      handleSetCookie(ACCESS_TOKEN_COOKIE, accessToken, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30, // set max age to 30 days
      });

      setUserData((prevUserInformation) => ({
        ...prevUserInformation,
        givenName: firstName ?? '',
        familyName: lastName ?? '',
        picture: picture ?? '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    loginMutation.data,
    checkMagicLinkToLoginMutation.data,
    signupMutation.data,
    resetPasswordMutation.data,
    repCompleteSignupMutation.data,
  ]);

  const values = useMemo(
    () => ({
      error: (loginMutation.error ||
        checkMagicLinkToLoginMutation.error ||
        signupMutation.error ||
        resetPasswordMutation.error) as APIError | undefined,
      isLoading:
        loginMutation.isPending ||
        checkMagicLinkToLoginMutation.isPending ||
        signupMutation.isPending ||
        resetPasswordMutation.isPending,
      login: loginMutation.mutateAsync,
      checkMagicLinkToLogin: checkMagicLinkToLoginMutation.mutateAsync,
      signup: signupMutation.mutateAsync,
      resetPass: resetPasswordMutation.mutateAsync,
      isSuccess:
        loginMutation.isSuccess ||
        checkMagicLinkToLoginMutation.isSuccess ||
        signupMutation.isSuccess ||
        resetPasswordMutation.isSuccess,
      repCompleteSignupMutation,
    }),
    [
      loginMutation,
      checkMagicLinkToLoginMutation,
      signupMutation,
      resetPasswordMutation,
      repCompleteSignupMutation,
    ],
  );

  return <AuthContext.Provider value={values} {...props} />;
};
