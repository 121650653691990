import {useQuery, QueryClient} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {PageDto} from '@/models';
import {read, SERVICE_DEFAULT_VERSIONS} from '@/services/api';

export async function getPageBySetId(setId: string): Promise<PageDto | null> {
  let data: PageDto | null = null;

  if (!setId) return data;

  try {
    data = await read<PageDto>(
      'Pages/bysetid',
      {resourceId: setId},
      {},
      'pages',
      false,
      3,
    );
  } catch (e) {
    console.error(e);
  }

  if (data?.response === 404) {
    return null;
  }

  return data;
}

export async function getPage(
  brandSlug: string,
  bypassServer: boolean = false,
): Promise<PageDto | null> {
  let data: PageDto | null = null;

  if (!brandSlug) return data;

  try {
    data = await read<PageDto>(
      'Pages',
      {resourceId: brandSlug},
      {},
      'pages',
      false,
      SERVICE_DEFAULT_VERSIONS.pages,
      undefined,
      bypassServer,
    );
  } catch (e) {
    console.error(e);
  }

  if (data?.response === 404) {
    return null;
  }

  return data;
}

export const getPageQueryKey = (brandSlug: string) => ['brandSlug', brandSlug];

export function usePage(brandSlug: string, options = {}) {
  return useQuery<PageDto | null>({
    queryKey: getPageQueryKey(brandSlug),
    queryFn: () => getPage(brandSlug),
    refetchOnMount: false,
    ...options,
  });
}

export function getPagePrefetchQuery(
  brandSlug: string,
  bypassServer: boolean = false,
): IPrefetchedQuery {
  return {
    queryKey: getPageQueryKey(brandSlug),
    getFn: async () => await getPage(brandSlug, bypassServer),
  };
}

export async function fetchQueryPage(
  brandSlug: string,
  queryClient: QueryClient,
  bypassServer: boolean = false,
) {
  return await queryClient.fetchQuery({
    queryKey: getPageQueryKey(brandSlug),
    queryFn: async () => await getPage(brandSlug, bypassServer),
  });
}
