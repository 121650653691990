import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {ConstellationDrug} from '@/interfaces';
import {getDataFromLocalOrCdn} from '@/utils/files';

interface ConstellationDrugsResponse {
  drugs: ConstellationDrug[];
}

export async function getConstellationDrugs(): Promise<ConstellationDrug[]> {
  const response = await getDataFromLocalOrCdn<ConstellationDrugsResponse>(
    'constellation_drugs.json',
  );

  if (response.data) {
    return response?.data.drugs;
  }

  return [];
}

export const CONSTELLATION_DRUG_QUERY_KEY = ['constellation-drugs'];

export function useConstellationDrugs(options = {}) {
  return useQuery<ConstellationDrug[]>({
    queryKey: CONSTELLATION_DRUG_QUERY_KEY,
    queryFn: () => getConstellationDrugs(),
    placeholderData: [],
    ...options,
  });
}

export function getConstellationDrugsPrefetchQuery(): IPrefetchedQuery {
  return {
    queryKey: CONSTELLATION_DRUG_QUERY_KEY,
    getFn: () => getConstellationDrugs(),
  };
}
