'use client';
import React, {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {useLargeFilter, UseLargeFilterReturn} from '@/hooks';
import {
  usePovertyLimit,
  UsePovertyLimitReturn,
} from '@/hooks/usePovertyLimitFilter';
import {useQueryParams} from '@/hooks/useQueryParams';
import {useToggleFilter, UseToggleFilterReturn} from '@/hooks/useToggleFilter';
import {FacetValues} from '@/models';
import {financialAssistanceFiltersQueryParams} from '@/utils/financialAssistance';

type FinancialAssistanceFiltersContextType = {
  resetFilters: () => void;
  mobileModalOpen: boolean;
  handleMobileModalClose: () => void;
  handleMobileModalOpen: () => void;
  programTypeFilter: UseLargeFilterReturn;
  insuranceRequirementsFilter: UseLargeFilterReturn;
  usResidencyRequiredFilter: UseToggleFilterReturn;
  ageRequiredFilter: UseToggleFilterReturn;
  showFinancialRequirementsFilters: boolean;
  povertyLimitFilter: UsePovertyLimitReturn;
};

const FinancialAssistanceFiltersContext = createContext<
  FinancialAssistanceFiltersContextType | undefined
>(undefined);

type FinancialAssistanceFiltersProviderProps = {
  children: ReactNode;
  programTypeFacetValues?: FacetValues;
  insuranceRequirementsFacetValues?: FacetValues;
};

export const FinancialAssistanceFiltersProvider: FC<
  FinancialAssistanceFiltersProviderProps
> = ({children, programTypeFacetValues, insuranceRequirementsFacetValues}) => {
  const {searchParams, deleteParams, setParams} = useQueryParams();

  const programTypeFilter = useLargeFilter({
    facetValues: programTypeFacetValues,
    filterQueryParam: financialAssistanceFiltersQueryParams.programType,
  });

  const insuranceRequirementsFilter = useLargeFilter({
    facetValues: insuranceRequirementsFacetValues,
    filterQueryParam:
      financialAssistanceFiltersQueryParams.insuranceRequirements,
  });

  const getBooleanFromSearchParams = useCallback(
    (key: string) => {
      const value = searchParams?.get(key);
      return typeof value === 'string' ? value === 'true' : true;
    },
    [searchParams],
  );

  const f2InitialState = useMemo(
    () =>
      getBooleanFromSearchParams(
        financialAssistanceFiltersQueryParams.usResidencyRequired,
      ),
    [financialAssistanceFiltersQueryParams.usResidencyRequired],
  );

  const f3InitialState = useMemo(
    () =>
      getBooleanFromSearchParams(
        financialAssistanceFiltersQueryParams.ageRequired,
      ),
    [financialAssistanceFiltersQueryParams.ageRequired],
  );

  const usResidencyRequiredFilter = useToggleFilter({
    initialState: f2InitialState,
    onFilterChange: (checked) => {
      setParams({
        [financialAssistanceFiltersQueryParams.usResidencyRequired]: checked
          ? 'true'
          : 'false',
      });
    },
  });

  const ageRequiredFilter = useToggleFilter({
    initialState: f3InitialState,
    onFilterChange: (checked) => {
      setParams({
        [financialAssistanceFiltersQueryParams.ageRequired]: checked
          ? 'true'
          : 'false',
      });
    },
  });

  const povertyLimitFilter = usePovertyLimit({setParams, searchParams});

  const [mobileModalOpen, setMobileModalOpen] = useState(false);

  const handleMobileModalOpen = useCallback(() => {
    setMobileModalOpen(true);
  }, [setMobileModalOpen]);

  const handleMobileModalClose = useCallback(() => {
    setMobileModalOpen(false);
  }, [setMobileModalOpen]);

  const resetFilters = useCallback(() => {
    programTypeFilter.uncheckAllLargeFilterOptions();
    insuranceRequirementsFilter.uncheckAllLargeFilterOptions();
    usResidencyRequiredFilter.setFilterState(true);
    ageRequiredFilter.setFilterState(true);
    povertyLimitFilter.handleResetFinancialRequirements();
    deleteParams([
      financialAssistanceFiltersQueryParams.programType,
      financialAssistanceFiltersQueryParams.insuranceRequirements,
      financialAssistanceFiltersQueryParams.usResidencyRequired,
      financialAssistanceFiltersQueryParams.ageRequired,
      'household',
      'income',
      'country',
    ]);
  }, [
    programTypeFilter,
    insuranceRequirementsFilter,
    usResidencyRequiredFilter,
    ageRequiredFilter,
    povertyLimitFilter,
    deleteParams,
  ]);

  const showFinancialRequirementsFilters = useMemo(
    () =>
      programTypeFilter.selectedFilterOptions.some((val) =>
        val.id.toLowerCase().includes('charity'),
      ),
    [programTypeFilter.selectedFilterOptions],
  );

  const value = useMemo(
    () => ({
      resetFilters,
      mobileModalOpen,
      handleMobileModalClose,
      handleMobileModalOpen,
      programTypeFilter,
      insuranceRequirementsFilter,
      usResidencyRequiredFilter,
      showFinancialRequirementsFilters,
      povertyLimitFilter,
      ageRequiredFilter,
    }),
    [
      resetFilters,
      mobileModalOpen,
      handleMobileModalClose,
      handleMobileModalOpen,
      programTypeFilter,
      insuranceRequirementsFilter,
      usResidencyRequiredFilter,
      ageRequiredFilter,
      showFinancialRequirementsFilters,
      povertyLimitFilter,
    ],
  );

  return (
    <FinancialAssistanceFiltersContext.Provider value={value}>
      {children}
    </FinancialAssistanceFiltersContext.Provider>
  );
};

export const useFinancialAssistanceFilters = () => {
  const context = useContext(FinancialAssistanceFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useFinancialAssistanceFilters must be used within a FinancialAssistanceFiltersProvider',
    );
  }
  return context;
};
