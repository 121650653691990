import {useCallback, useState} from 'react';
import {COUNTRY_CODES} from '@/constants/povertyGuidelines';
import {ReadonlyURLSearchParams} from 'next/navigation';

export const usePovertyLimit = ({
  searchParams,
  setParams,
}: {
  searchParams: ReadonlyURLSearchParams | null;
  setParams: (newParams: {[key: string]: string | string[]}) => void;
}) => {
  const [income, setIncome] = useState(searchParams?.get('income') ?? '');
  const [country, setCountry] = useState(
    searchParams?.get('country') ?? COUNTRY_CODES.US,
  );
  const household = searchParams?.get('household');
  const [amount, setAmount] = useState(parseInt((household as string) ?? '0'));

  const handleResetFinancialRequirements = useCallback(() => {
    setIncome('');
    setCountry(COUNTRY_CODES.US);
    setAmount(0);
  }, []);

  const setFinancialRequirementsFilters = useCallback(
    () =>
      ({
        country,
        household,
        income,
      }: {
        income: number;
        household: string;
        country: string;
      }) => {
        if (!searchParams) return;
        const params = new URLSearchParams(searchParams.toString());
        const oldParams: Record<string, any> = {};

        params.forEach((value, key) => {
          if (oldParams[key]) {
            oldParams[key] = Array.isArray(oldParams[key])
              ? [...oldParams[key], value]
              : [oldParams[key], value];
          } else {
            oldParams[key] = value;
          }
        });

        setParams({
          ...oldParams,
          ['income']: income.toString(),
          ['household']: household,
          ['country']: country,
        });
      },
    [searchParams, income, household, country],
  );

  return {
    handleResetFinancialRequirements,
    setFinancialRequirementsFilters,
    income,
    setIncome,
    country,
    setCountry,
    amount,
    setAmount,
  };
};

export type UsePovertyLimitReturn = ReturnType<typeof usePovertyLimit>;
