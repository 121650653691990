import {useRouter} from '@/hooks/useRouter';

function useRepAuthPath() {
  const router = useRouter();

  const {locationId} = router.query;

  const basePath = `/reps/`;
  const loginPath = basePath + `login?locationId=${locationId}`;
  const signupPath = basePath + `signup?locationId=${locationId}`;
  const checkinPath = basePath + `checkin?locationId=${locationId}`;

  return {loginPath, signupPath, checkinPath};
}
export {useRepAuthPath};
