import {useCallback, useEffect, useLayoutEffect} from 'react';
import {CTA_BUTTON_IDS} from '@/constants/ctaServices';
import {useRouter} from '@/hooks/useRouter';
import detectIfElementAddedtoDOM from '@/utils/detectIfElementAddedtoDOM';

const canUseDOM = typeof window !== 'undefined';
const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

const useAutoOpenChatBox = () => {
  const router = useRouter();

  const autoOpenChatBox = useCallback(() => {
    // even when the element was added to the dom, we need to wait a little bit
    setTimeout(() => {
      // click cta button if help query param is set to open the chat box
      const ctaButtonId = CTA_BUTTON_IDS[router.query.help as string];
      if (ctaButtonId) {
        const element = document.getElementById(ctaButtonId);
        if (element) {
          element.click();
        }
      }
    }, 500);
  }, [router.query.help]);

  useIsomorphicLayoutEffect(() => {
    detectIfElementAddedtoDOM(
      'body',
      'intercom-lightweight-app',
      autoOpenChatBox,
    );
  }, [autoOpenChatBox]);
};

export {useAutoOpenChatBox};
