/* eslint-disable no-unused-vars */
export enum COUNTRY_CODES {
  US = 'US',
  AL = 'AL',
  HA = 'HA',
}

export const POVERTY_GUIDELINES = {
  [COUNTRY_CODES.US]: {
    1: 15650,
    2: 21150,
    3: 26650,
    4: 32150,
    5: 37650,
    6: 43150,
    7: 48650,
    8: 54150,
  },
  [COUNTRY_CODES.AL]: {
    1: 19550,
    2: 26430,
    3: 33310,
    4: 40190,
    5: 47070,
    6: 53950,
    7: 60830,
    8: 67710,
  },
  [COUNTRY_CODES.HA]: {
    1: 17990,
    2: 24320,
    3: 30650,
    4: 36980,
    5: 43310,
    6: 49640,
    7: 55970,
    8: 62300,
  },
};
