import {useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {ICompareIndication} from '@/models';
import {read, SERVICE_DEFAULT_VERSIONS} from '@/services/api';

export async function getCompareIndications(): Promise<ICompareIndication[]> {
  return await read<ICompareIndication[]>(
    'Indication/brands',
    {},
    {},
    'profile',
    false,
    SERVICE_DEFAULT_VERSIONS.profile,
    undefined,
    true,
  );
}

export const COMPARE_INDICATIONS_QUERY_KEY = ['compare-indications'];

export function useCompareIndications(options = {}) {
  return useQuery<ICompareIndication[]>({
    queryKey: COMPARE_INDICATIONS_QUERY_KEY,
    queryFn: () => getCompareIndications(),
    ...options,
  });
}

export function getCompareIndicationsPrefetchQuery(): IPrefetchedQuery {
  return {
    queryKey: COMPARE_INDICATIONS_QUERY_KEY,
    getFn: async () => await getCompareIndications(),
  };
}
