"use client"
import {createContext, FC, useContext, useState} from 'react';
interface IToastContext {
  isModalInsideSidePanel: boolean;
  setIsModalInsideSidePanel: (bool: boolean) => void;
}

const defaultState: IToastContext = {
  isModalInsideSidePanel: false,
  setIsModalInsideSidePanel: () => {},
};

export const ToastContext = createContext(defaultState);

export const useToastContext = () => useContext(ToastContext);

export const ToastContextProvider: FC = (props) => {
  const [isModalInsideSidePanel, setIsModalInsideSidePanel] = useState(false);

  const values = {
    isModalInsideSidePanel,
    setIsModalInsideSidePanel,
  };

  return <ToastContext.Provider value={values} {...props} />;
};
