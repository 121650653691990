"use client"
import {
  FC,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useState,
  useMemo,
} from 'react';
import {useUser} from '@/hooks';
import useUserData from '@/hooks/useUserData';

interface IFormResourcesContext {
  isEmailModalOpen: boolean;
  setIsEmailModalOpen: Dispatch<SetStateAction<boolean>>;
  canDownload: boolean;
}

const defaultState: IFormResourcesContext = {
  isEmailModalOpen: false,
  setIsEmailModalOpen: () => {},
  canDownload: false,
};

const FormResourcesContext = createContext<IFormResourcesContext>(defaultState);

export const useFormResourcesContext = () => useContext(FormResourcesContext);

export const FormResourcesProvider: FC = (props) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const {logged: isLogged} = useUser();

  const {userData} = useUserData();

  const canDownload = useMemo(() => {
    const hasPriorAuthEmail = !!userData?.priorAuthFormSubmitted;
    return isLogged || hasPriorAuthEmail ? true : false;
  }, [userData?.priorAuthFormSubmitted, isLogged]);

  const values = {
    isEmailModalOpen,
    setIsEmailModalOpen,
    canDownload,
  };

  return <FormResourcesContext.Provider value={values} {...props} />;
};
