"use client"
import {FC, useState, createContext, useContext, useMemo} from 'react';
import {DrugSearchResult, IResult} from '@/interfaces';

const SELECTED_RESULTS_LIMIT = 2;

interface SearchListProps {
  selectedResults: IResult[];
}

interface ISearchListContext {
  isSearchDisabled: boolean;
  isTyping: boolean;
  query: string;
  selectedValue: DrugSearchResult | undefined;
  selectedResults: IResult[];
  wasModified: boolean;
  showSelectedResults: boolean;
  addToSelectedResults: (result: IResult) => void;
  removeFromSelectedResults: (set_id: string) => void;
  setIsTyping: (value: boolean) => void;
  setQuery: (query: string) => void;
  setSelectedValue: (searchedItem: DrugSearchResult) => void;
  setShowSelectedResults: (value: boolean) => void;
}

const defaultState = {
  showSelectedResults: false,
  isSearchDisabled: false,
  isTyping: false,
  query: '',
  selectedValue: undefined,
  selectedResults: [],
  wasModified: false,
  addToSelectedResults: () => {},
  removeFromSelectedResults: () => {},
  setIsTyping: () => {},
  setQuery: () => {},
  setSelectedValue: () => {},
  setShowSelectedResults: () => {},
};

export const SearchListContext =
  createContext<ISearchListContext>(defaultState);

export const useSearchListContext = () => useContext(SearchListContext);

export const SearchListProvider: FC<SearchListProps> = ({
  selectedResults: defaultSelectedResults = [],
  ...props
}) => {
  const [query, setQuery] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<DrugSearchResult>();
  const [wasModified, setModified] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [showSelectedResults, setShowSelectedResults] = useState(true);

  const [selectedResults, setSelectedResults] = useState<IResult[]>(
    defaultSelectedResults,
  );

  const removeFromSelectedResults = (set_id: string) => {
    setSelectedResults((prev) => prev.filter((r) => r.set_id !== set_id));
  };

  const addToSelectedResults = (result: IResult) => {
    setModified(true);
    setSelectedResults((prev) => [...prev, result]);
  };

  const isSearchDisabled = useMemo(
    () => selectedResults?.length >= SELECTED_RESULTS_LIMIT,
    [selectedResults?.length],
  );

  const values = {
    isSearchDisabled,
    isTyping,
    query,
    selectedValue,
    selectedResults,
    wasModified,
    addToSelectedResults,
    removeFromSelectedResults,
    setIsTyping,
    setQuery,
    setSelectedValue,
    showSelectedResults,
    setShowSelectedResults,
  };

  return <SearchListContext.Provider value={values} {...props} />;
};
