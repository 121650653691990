// basic section ids shared amongst all drugs
export const STANDARD_SECTION_IDS = {
  summary: 'summary',
  coverage: 'coverage',
  drugLabel: 'drug_label',
  pubMed: 'pub_med_tm_news',
  peopleAlsoAsk: 'people_also_ask',
};

export const FIXED_RESOURCE_SECTIONS = [
  'prior_authorization',
  'financial_assistance',
  'patient_education',
];

export const STANDARD_SECTION_IDS_EXPERIMENT = {
  drugLabel: 'drug_label',
  peopleAlsoAsk: 'people_also_ask',
};

// section ids only for enhanced drugs
export const ENHANCED_SECTION_IDS = {
  requestSamples: 'samples',
  priorAuthorization: 'prior_authorization',
  financialAssistance: 'financial_assistance',
  patientEducation: 'patient_education',
};

// all navigable section ids (anchors)
export const SECTION_IDS = {
  ...STANDARD_SECTION_IDS,
  ...ENHANCED_SECTION_IDS,
};

export const SECTION_IDS_EXPERIMENT = {
  ...STANDARD_SECTION_IDS_EXPERIMENT,
  ...ENHANCED_SECTION_IDS,
};

// not used for the pivot navigation menu
export const OTHER_SECTION_IDS = {
  dosingAndAdmiministration: 'dosing_and_administration',
  msl: 'medical_science_liaison',
  reportAdverseEvent: 'report_adverse_event',
  coverageRestrictions: 'coverage_restrictions',
  reps: 'reps',
};

// This maintains the order of every section as it appears in the page
export const FULL_SECTION_IDS = {
  ...SECTION_IDS,
  ...OTHER_SECTION_IDS,
};

export const SECTION_IDS_ORDER: {[key: string]: number} = {
  summary: 0,
  biomarker_testing: 1,
  coverage: 2,
  drug_label: 3,
  samples: 4,
  prior_authorization: 5,
  financial_assistance: 7,
  pub_med_tm_news: 8,
  patient_education: 9,
  people_also_ask: 10,
};

export const SECTION_IDS_EXPERIMENT_ORDER: {[key: string]: number} = {
  financial_assistance: 1,
  prior_authorization: 2,
  samples: 3,
  dosing_and_administration: 4,
  pub_med_tm_news: 6,
  patient_education: 7,
  people_also_ask: 8,
  biomarker_testing: 9,
  drug_label: 10,
  coverage: 11,
};

export const PHARMACIES_SECTION_IDS = {
  overview: 'overview',
  resources: 'resources',
  healthPlans: 'health_plans',
  payerServices: 'payer_services',
  patientServices: 'patient_services',
  pharmaceuticalServices: 'pharmaceutical_services',
};

export const PHARMACY_PAGE_SECTION_IDS = {
  healthPlans: 'health_plans',
  pharmaServices: 'pharma_services',
  patientServices: 'patient_services',
  conditions: 'conditions',
  insuranceSupport: 'insurance_support',
  forms: 'forms',
  priceList: 'price_list',
};

export const DISTRIBUTION_PHARMACY_SECTION_IDS = {
  healthPlans: 'health_plans',
  pharmaServices: 'pharma_services',
  patientServices: 'patient_services',
  conditions: 'conditions',
  insuranceSupport: 'insurance_support',
  limitedDistribution: 'limited_distribution',
  exclusiveDistribution: 'exclusive_distribution',
};

export const SUBPAGES_SECTION_IDS = {
  label: SECTION_IDS.drugLabel,
  'patient-resources': SECTION_IDS.patientEducation,
  'prior-authorizations': SECTION_IDS.priorAuthorization,
  'financial-assistance': SECTION_IDS.financialAssistance,
};
