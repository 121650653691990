import {useQuery, QueryClient} from '@tanstack/react-query';
import {IBrandDto} from '@/models';
import {read, SERVICE_DEFAULT_VERSIONS} from '@/services/api';

interface IIndicationDrugsResponse {
  links: any[];
  results: IBrandDto[];
  sortField: any;
  next: number;
  take: number;
  total: number;
  start: number;
}

export async function getIndicationDrugs(
  indicationName: string,
  bypassServer: boolean = false,
): Promise<IBrandDto[]> {
  let data: IBrandDto[] = [];

  try {
    const response = await read<IIndicationDrugsResponse>(
      'Indication',
      {
        resourceId: encodeURIComponent(indicationName),
      },
      {},
      'profile',
      false,
      SERVICE_DEFAULT_VERSIONS.profile,
      undefined,
      bypassServer,
    );
    data = response.results;
  } catch (error) {
    console.log(error);
  }

  return data;
}

export const getIndicationDrugsQueryKey = (indicationName: string) => [
  'indication-drugs',
  indicationName,
];

export function useIndicationDrugs(indicationName: string, options = {}) {
  return useQuery<IBrandDto[]>({
    queryKey: getIndicationDrugsQueryKey(indicationName),
    queryFn: () => getIndicationDrugs(indicationName),
    ...options,
  });
}

export function fetchQueryIndicationDrugs(
  indicationName: string,
  queryClient: QueryClient,
  bypassServer: boolean = false,
) {
  return queryClient.fetchQuery({
    queryKey: getIndicationDrugsQueryKey(indicationName),
    queryFn: async () => await getIndicationDrugs(indicationName, bypassServer),
  });
}

export function getIndicationDrugsPrefetchQuery(
  indicationName: string,
  bypassServer: boolean = false,
) {
  return {
    queryKey: getIndicationDrugsQueryKey(indicationName),
    getFn: () => getIndicationDrugs(indicationName, bypassServer),
  };
}
