import {usePathname, useSearchParams, useRouter} from 'next/navigation';
import {useCallback} from 'react';

export function useQueryParams() {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const setParams = useCallback(
    (newParams: {[key: string]: string | string[]}) => {
      const params = new URLSearchParams(searchParams?.toString() ?? '');
      Object.entries(newParams).forEach(([name, value]) => {
        if (Array.isArray(value)) {
          params.delete(name);
          value.forEach((v) => params.append(name, v));
        } else if (value) {
          params.set(name, value);
        }
      });
      router.push(`${pathname}?${params.toString()}`);
    },
    [searchParams, pathname, router],
  );

  const deleteParams = useCallback(
    (keys: string[]) => {
      if (!searchParams) {
        return;
      }
      const params = new URLSearchParams(searchParams.toString());
      keys.forEach((key) => params.delete(key));
      router.push(`${pathname}?${params.toString()}`);
    },
    [searchParams, pathname, router],
  );

  return {searchParams, pathname, router, setParams, deleteParams};
}
