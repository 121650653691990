import {QueryClient, useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {EnhancedContentDto} from '@/models';
import {read} from '@/services/api';

export async function getEnhancedContent(
  setId: string,
): Promise<EnhancedContentDto | null> {
  let data = null;

  if (!setId) return data;

  try {
    data = await read<EnhancedContentDto>(
      'enhancedContent',
      {parentName: 'contentful', resourceId: setId},
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }
  return data;
}

export const getEnhancedContentQueryKey = (setId: string) => [
  'enhanced-content',
  setId,
];

export function useEnhancedContent(setId: string, options = {}) {
  return useQuery<EnhancedContentDto | null>({
    queryKey: getEnhancedContentQueryKey(setId),
    queryFn: () => getEnhancedContent(setId),
    ...options,
  });
}

export async function fetchQueryEnhancedContent(
  setId: string,
  queryClient: QueryClient,
) {
  return await queryClient.fetchQuery({
    queryKey: getEnhancedContentQueryKey(setId),
    queryFn: async () => await getEnhancedContent(setId),
  });
}

export function getEnhancedContentPrefetchQuery(
  setId: string,
): IPrefetchedQuery {
  return {
    queryKey: getEnhancedContentQueryKey(setId),
    getFn: async () => await getEnhancedContent(setId),
  };
}
