"use client"
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useRouter} from '@/hooks/useRouter';

interface IBlackBoxWarningContext {
  idxOpened: number;
  setIdxOpened: (_idx: number) => void;
}

const defaultState: IBlackBoxWarningContext = {
  idxOpened: -1,
  setIdxOpened: () => {},
};

const BlackBoxWarningContext = createContext(defaultState);

export const useBlackBoxWarningContext = () =>
  useContext(BlackBoxWarningContext);

export const BlackBoxWarningProvider: FC = (props) => {
  const [idxOpened, setIdxOpened] = useState(-1);
  const router = useRouter();

  useEffect(() => {
    setIdxOpened(-1);
  }, [router]);

  const values = useMemo(
    () => ({
      idxOpened,
      setIdxOpened,
    }),
    [idxOpened, setIdxOpened],
  );

  return <BlackBoxWarningContext.Provider value={values} {...props} />;
};
