import {useCallback} from 'react';
import {useToggle} from 'usehooks-ts';

type UseToggleFilterProps = {
  initialState: boolean;
  onFilterChange?: (_checked: boolean) => void;
};

export const useToggleFilter = ({
  initialState,
  onFilterChange,
}: UseToggleFilterProps) => {
  const [filterState, toggle, setFilterState] = useToggle(initialState);

  const resetFilterState = useCallback(() => {
    setFilterState(initialState);
  }, [setFilterState, initialState]);

  const getFilterChangeHandler = useCallback(
    (runOnChange = false) => (checked?: boolean) => {
      toggle();
      if (runOnChange) {
        onFilterChange?.(checked ?? false);
      }
    },
    [toggle, onFilterChange],
  );

  return {
    filterState,
    setFilterState,
    getFilterChangeHandler,
    resetFilterState,
  };
};

export type UseToggleFilterReturn = ReturnType<typeof useToggleFilter>;
