import {QueryClient, useQuery} from '@tanstack/react-query';
import {read} from '@/services/api';

export interface PracticeDto {
  domain: string;
  name: string;
  id: string;
}

export async function getPracticeName(
  domains: string,
): Promise<PracticeDto[] | null> {
  let data = null;

  if (!domains) return data;

  return await read<PracticeDto[]>(
    'Practice',
    {
      params: {
        domains,
      },
    },
    {},
    'profile',
  );
}

export const getPracticeNameQueryKey = (domain: string) => [
  'practice-name',
  domain,
];

export function usePracticeName(domain: string, options = {}) {
  return useQuery<PracticeDto[] | null>({
    queryKey: getPracticeNameQueryKey(domain),
    queryFn: () => getPracticeName(domain),
    ...options,
  });
}

export async function fecthQueryPracticeName(
  domain: string,
  queryClient: QueryClient,
): Promise<PracticeDto[] | null> {
  return await queryClient.fetchQuery({
    queryKey: getPracticeNameQueryKey(domain),
    queryFn: () => getPracticeName(domain),
  });
}
