'use client';
import {ParsedUrlQuery} from 'querystring';
import {useRouter as compatRouter} from 'next/compat/router';
import * as nextNavigation from 'next/navigation';
import * as nextPagesRouter from 'next/router';

export function useIsAppRouter() {
  // it returns the router instance if it is rendered in the pages router
  // returns null if it is in the app router
  const router = compatRouter();
  return !router;
}

export function useRouter() {
  // Use a boolean flag to determine if `usePathname` exists (App Router)
  const isAppRouter = useIsAppRouter();

  if (isAppRouter) {
    const pathname = nextNavigation.usePathname() ?? '';
    const searchParams = nextNavigation.useSearchParams();
    const query = searchParams
      ? Object.fromEntries(searchParams.entries())
      : {};

    const params = nextNavigation.useParams();

    return {
      ...nextNavigation.useRouter(),
      asPath: pathname,
      pathname,
      query: {...query, ...params},
    };
  }

  // Otherwise, use `next/router`'s useRouter (Pages Router)
  return nextPagesRouter.useRouter();
}

type ISearchQuery = {[k: string]: string} | ParsedUrlQuery;

export function getNewUrlWithQuery(queries: ISearchQuery) {
  const searchParams = nextNavigation.useSearchParams();
  const pathname = nextNavigation.usePathname();

  // get current search params
  const params = new URLSearchParams(
    searchParams ? Array.from(searchParams.entries()) : [],
  );

  // Add new search params
  for (const [key, value] of Object.entries(queries)) {
    if (key && value) {
      params.set(key, value as string);
    }
  }

  const search = params.toString();
  const query = search ? `?${search}` : '';
  return `${pathname}${query}`;
}
