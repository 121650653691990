import {useMutation} from '@tanstack/react-query';
import {LoginDto} from './useLogin';
import {RegistrationProcessType} from '@/models/addEmail';
import {update} from '@/services/api';

export interface ISignup {
  firstName: string;
  lastName: string;
  credentials?: string;
  practiceName: string;
  writePrescription: boolean | string;
  password: string;
  email: string;
  token: string;
  process: RegistrationProcessType;
  kind?: string;
  hubspotCookie?: string;
}

async function signup(params: ISignup) {
  return await update<LoginDto>(
    'ConfirmSignUp',
    {
      parentName: 'Auth',
      params,
    },
    {method: 'PUT'},
    'profile',
    false,
    2,
  );
}

export function useSignup() {
  return useMutation({mutationFn: signup});
}
