"use client"
import {useRouter} from 'next/router';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ILoadingContext {
  isLoading: boolean;
  setIsLoading: (_bool: boolean) => void;
}

const defaultState: ILoadingContext = {
  isLoading: false,
  setIsLoading: () => {},
};

const LoadingContext = createContext(defaultState);

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingProvider: FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const isServer = typeof window === 'undefined';
  const router = useRouter();

  useEffect(() => {
    const handleStart = () => {
      setIsLoading(true);
    };
    const handleComplete = () => {
      setIsLoading(false);
    };

    router.events.on('routeChangeError', handleComplete);
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);

    return () => {
      router.events.off('routeChangeError', handleComplete);
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
    };
  }, [router]);

  const values = useMemo(
    () => ({
      isLoading: isServer ? false : isLoading,
      setIsLoading,
    }),
    [isLoading, setIsLoading, isServer],
  );

  return <LoadingContext.Provider value={values} {...props} />;
};
