import {useQuery} from '@tanstack/react-query';
import {PracticeLocationDto} from '@/models';
import {read} from '@/services/api';

export async function getPracticeLocation(
  locationId: string,
): Promise<PracticeLocationDto | null> {
  let data = null;

  if (!locationId) return data;

  try {
    data = await read<PracticeLocationDto>(
      'Location',
      {resourceId: locationId},
      {},
      'profile',
      true,
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export const getPracticeLocationQueryKey = (locationId: string) => [
  'practice-id',
  locationId,
];

export function usePracticeLocation(locationId: string, options = {}) {
  return useQuery<PracticeLocationDto | null>({
    queryKey: getPracticeLocationQueryKey(locationId),
    queryFn: () => getPracticeLocation(locationId),
    enabled: !!locationId,
    ...options,
  });
}
