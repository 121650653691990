import {useQuery} from '@tanstack/react-query';
import {DDI_INGREDIENTS_BRAND_NAMES} from '@/constants/flags';
import {useFlag} from '@/context';
import {IInteractionDataItem} from '@/models/interactions';
import {read} from '@/services/api';

export async function getInteractionsByDrug(
  setId: string,
  totalOnly: boolean,
  labelsNdSlugs: boolean,
): Promise<IInteractionDataItem | null> {
  let data = null;

  if (!setId) return data;

  try {
    return await read<IInteractionDataItem>(
      'interactionsByDrug',
      {
        parentName: 'ddi',
        params: {
          drug: setId,
          totalOnly: totalOnly,
          labelsNdSlugs,
        },
      },
      {},
      'nextApi',
    );
  } catch (e) {
    console.error(e);
  }

  return data;
}

export function useInteractionsByDrug(
  setId: string,
  totalOnly: boolean = false,
  options = {},
) {
  const ddiIngredientNamesEnabled = useFlag(DDI_INGREDIENTS_BRAND_NAMES);

  return useQuery<IInteractionDataItem | null>({
    queryKey: [
      'interactions-by-drug',
      {setId, totalOnly, ddiIngredientNamesEnabled},
    ],
    queryFn: () =>
      getInteractionsByDrug(setId, totalOnly, ddiIngredientNamesEnabled),
    ...options,
  });
}
