import {IComboBoxOption} from '@prescriberpoint/ui';
import {ISearchFilterOption} from '@prescriberpoint/ui/components';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQueryParams} from '@/hooks/useQueryParams';
import {FacetValues} from '@/models';

export interface GroupedFilterOptions {
  label: string;
  options: ISearchFilterOption[];
}

export interface UseLargeFilterProps {
  filterQueryParam: string;
  facetValues?: FacetValues;
}

export interface UseLargeFilterModalProps {
  filterOptions: ISearchFilterOption[];
}

const useLargeFilterModal = ({filterOptions}: UseLargeFilterModalProps) => {
  const [filterModalOptions, setFilterModalOptions] = useState<
    ISearchFilterOption[]
  >([]);

  const [isLargeFilterModalOpen, setIsLargeFilterModalOpen] = useState(false);

  const handleLargeFilterModalOpen = useCallback(() => {
    setIsLargeFilterModalOpen(true);
  }, [setIsLargeFilterModalOpen]);

  const handleLargeFilterModalClose = useCallback(() => {
    setIsLargeFilterModalOpen(false);
  }, [setIsLargeFilterModalOpen]);

  const largeFilterModalSelectedOptions = useMemo(
    () => filterModalOptions.filter((o) => o.isChecked),
    [filterModalOptions],
  );

  const largeFilterModalSelectedOptionValues = useMemo(
    () => largeFilterModalSelectedOptions.map((o) => o.value),
    [largeFilterModalSelectedOptions],
  );

  const groupedLargeFilterOptionsByLetter: GroupedFilterOptions[] =
    useMemo(() => {
      // Create a Map to group options by first letter
      const groupedMap = filterModalOptions.reduce((acc, option) => {
        const firstLetter = option.label.charAt(0).toUpperCase();
        if (!acc.has(firstLetter)) {
          acc.set(firstLetter, []);
        }
        acc.get(firstLetter)?.push(option);
        return acc;
      }, new Map<string, ISearchFilterOption[]>());

      // Convert Map to array and sort by letter
      const groupedArray = Array.from(groupedMap.entries()).map(
        ([letter, opts]) => {
          const sortedOptions = opts.toSorted((a, b) =>
            a.label.localeCompare(b.label),
          );
          return {
            label: letter,
            options: sortedOptions,
          };
        },
      );

      // Sort groups alphabetically
      return groupedArray.toSorted((a, b) => a.label.localeCompare(b.label));
    }, [filterModalOptions]);

  const resetLargeFilterModalOptions = useCallback(() => {
    setFilterModalOptions(filterOptions);
  }, [filterOptions, setFilterModalOptions]);

  const handleLargeFilterModalOptionClick = useCallback(
    (option: ISearchFilterOption, checked: boolean) => {
      setFilterModalOptions((prevOptions) => {
        const updatedOptions = prevOptions.map((o) => ({
          ...o,
          isChecked: o.value === option.value ? checked : o.isChecked,
        }));
        return updatedOptions;
      });
    },
    [],
  );

  const handleLargeFilterModalSearchOptionClick = useCallback(
    (option: IComboBoxOption | null) => {
      if (option) {
        setFilterModalOptions((prevOptions) => {
          const updatedOptions = prevOptions.map((o) => ({
            ...o,
            isChecked: o.value === option.value ? true : o.isChecked,
          }));
          return updatedOptions;
        });
      }
    },
    [],
  );

  useEffect(() => {
    resetLargeFilterModalOptions();
  }, [resetLargeFilterModalOptions]);

  return {
    filterModalOptions,
    handleLargeFilterModalOptionClick,
    handleLargeFilterModalSearchOptionClick,
    groupedLargeFilterOptionsByLetter,
    isLargeFilterModalOpen,
    handleLargeFilterModalOpen,
    handleLargeFilterModalClose,
    largeFilterModalSelectedOptionValues,
    resetLargeFilterModalOptions,
    largeFilterModalSelectedOptions,
  };
};

/**
 * Custom hook for managing large filter options
 * @param filterQueryParam - Query parameter for large filter
 * @param facetValues - Facet values containing large data
 * @returns Object containing large options and handlers
 */
export const useLargeFilter = ({
  filterQueryParam,
  facetValues,
}: UseLargeFilterProps) => {
  const {searchParams, setParams, deleteParams} = useQueryParams();

  const [filterOptions, setFilterOptions] = useState<ISearchFilterOption[]>([]);

  const {
    filterModalOptions,
    handleLargeFilterModalOptionClick,
    handleLargeFilterModalSearchOptionClick,
    groupedLargeFilterOptionsByLetter,
    isLargeFilterModalOpen,
    handleLargeFilterModalOpen,
    handleLargeFilterModalClose,
    largeFilterModalSelectedOptionValues,
    resetLargeFilterModalOptions,
    largeFilterModalSelectedOptions,
  } = useLargeFilterModal({
    filterOptions,
  });

  const filterParams = useMemo(
    () => searchParams?.getAll(filterQueryParam) ?? [],
    [filterQueryParam, searchParams],
  );

  const setFilterParams = useCallback(
    (values: string[]) => {
      setParams({[filterQueryParam]: values});
    },
    [filterQueryParam, setParams],
  );

  const deleteFilterParams = useCallback(() => {
    deleteParams([filterQueryParam]);
  }, [deleteParams, filterQueryParam]);

  const selectedFilterOptions = useMemo(
    () => filterOptions.filter((o) => o.isChecked),
    [filterOptions],
  );

  const selectedOptionValues = useMemo(
    () => selectedFilterOptions.map((o) => o.value),
    [selectedFilterOptions],
  );

  const searchOptions: IComboBoxOption[] = useMemo(
    () =>
      filterOptions.map((o, index) => ({
        id: index,
        value: o.value,
      })),
    [filterOptions],
  );

  const uncheckAllLargeFilterOptions = useCallback(
    () =>
      setFilterOptions((prev) => prev.map((o) => ({...o, isChecked: false}))),
    [setFilterOptions],
  );

  /**
   * Creates large filter options from facet values
   */
  const createLargeFilterOptions = useCallback(
    (
      facetValues: Record<string, number>,
      selectedValues: string[],
    ): ISearchFilterOption[] =>
      Object.entries(facetValues).map(([label]) => ({
        id: label,
        label,
        value: label,
        isChecked: selectedValues.includes(label),
      })),
    [],
  );

  /**
   * Updates URL parameters with selected filterValues
   */
  const applyLargeFilter = useCallback(
    (selectedValues: string[]) => {
      if (selectedValues.length) {
        setFilterParams(selectedValues);
      } else {
        deleteFilterParams();
      }
    },
    [setFilterParams, deleteFilterParams],
  );

  const getLargeFilterToggleMapper = useCallback(
    (checkedOption: ISearchFilterOption, checked: boolean) =>
      (option: ISearchFilterOption) => ({
        ...option,
        isChecked: option.id === checkedOption.id ? checked : option.isChecked,
      }),
    [],
  );

  /**
   * Handles large filter option selection/deselection
   */
  const getLargeFilterOptionClickHandler = useCallback(
    (applyFilter?: boolean) =>
      (option: ISearchFilterOption, checked: boolean) => {
        setFilterOptions((prevOptions) => {
          const updatedOptions = prevOptions.map(
            getLargeFilterToggleMapper(option, checked),
          );
          return updatedOptions;
        });

        if (applyFilter) {
          const updatedOptions = filterOptions.map(
            getLargeFilterToggleMapper(option, checked),
          );
          applyLargeFilter(
            updatedOptions.filter((opt) => opt.isChecked).map((opt) => opt.id),
          );
        }
      },
    [getLargeFilterToggleMapper, filterOptions, applyLargeFilter],
  );

  const hasActiveSearch = useMemo(() => {
    const q = searchParams?.get('q');
    return q !== undefined;
  }, [searchParams]);

  // Initialize and update search results large filter options when facets or URL params change
  useEffect(() => {
    if (facetValues) {
      const options = createLargeFilterOptions(facetValues, filterParams);
      if (options.length === 1 && hasActiveSearch) {
        options[0].isChecked = true;
        setFilterParams([options[0].value]);
      }
      setFilterOptions(options);
    } else {
      setFilterOptions([]);
      deleteFilterParams();
    }
  }, [
    filterParams,
    searchParams,
    deleteParams,
    hasActiveSearch,
    setParams,
    createLargeFilterOptions,
    facetValues,
    setFilterParams,
    deleteFilterParams,
  ]);

  return {
    filterOptions,
    getLargeFilterOptionClickHandler,
    selectedFilterOptions,
    uncheckAllLargeFilterOptions,
    applyLargeFilter,
    selectedOptionValues,
    groupedLargeFilterOptionsByLetter,
    isLargeFilterModalOpen,
    handleLargeFilterModalOpen,
    handleLargeFilterModalClose,
    deleteFilterParams,
    searchOptions,
    handleLargeFilterModalSearchOptionClick,
    resetLargeFilterModalOptions,
    filterModalOptions,
    largeFilterModalSelectedOptions,
    largeFilterModalSelectedOptionValues,
    handleLargeFilterModalOptionClick,
  };
};

export type UseLargeFilterReturn = ReturnType<typeof useLargeFilter>;
