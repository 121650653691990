import {useIsConstellationDrug} from './useIsConstellationDrug';
import {SUBPAGES_SECTION_IDS} from '@/constants/sectionIDs';
import {useRouter} from '@/hooks/useRouter';
import {PageDto} from '@/models';
import {usePage} from '@/queries';
import {getSetIdFromPage, sanitizeSlug} from '@/utils/slug';

export function useCurrentSlug() {
  const router = useRouter();
  const {slug, searchId, practiceId, section} = router.query;
  const santizedSlug = sanitizeSlug(slug as string);
  const {data: page} = usePage(santizedSlug);
  const setId = getSetIdFromPage(page as PageDto);
  const isConstellationDrug = useIsConstellationDrug(santizedSlug);

  return {
    setId,
    searchId: searchId as string,
    practiceId: practiceId as string,
    slug: santizedSlug,
    section: section as keyof typeof SUBPAGES_SECTION_IDS,
    isConstellationDrug,
  };
}
